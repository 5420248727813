import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./RedeemsUIHelpers";

const RedeemsUIContext = createContext();

export function useRedeemsUIContext() {
  return useContext(RedeemsUIContext);
}

export const RedeemsUIConsumer = RedeemsUIContext.Consumer;

export function RedeemsUIProvider({redeemsUIEvents, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const [ids, setIds] = useState([]);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initRedeem = {
    _id: undefined,
    username: "",
    referCode: "",
    device: "",
    geo: "",
    ipSignup: "Female",
    points: 0,
    createdAt: "",
    isActive: "",
    provision: 0,
    isVerfied: 1
  };

  const value = {
    queryParams,
    setQueryParamsBase,
    ids,
    setIds,
    setQueryParams,
    initRedeem,
    newRedeemButtonClick: redeemsUIEvents.newRedeemButtonClick,
    openEditRedeemDialog: redeemsUIEvents.openEditRedeemDialog,
    openDeleteRedeemDialog: redeemsUIEvents.openDeleteRedeemDialog,
    openDeleteRedeemsDialog: redeemsUIEvents.openDeleteRedeemsDialog,
    openFetchRedeemsDialog: redeemsUIEvents.openFetchRedeemsDialog,
    openUpdateRedeemsStatusDialog: redeemsUIEvents.openUpdateRedeemsStatusDialog,
    openFetchRedeemsDetailsDialog: redeemsUIEvents.openFetchRedeemsDetailsDialog
  };

  return <RedeemsUIContext.Provider value={value}>{children}</RedeemsUIContext.Provider>;
}