// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../../_redux/rewardItems/rewardItemsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./RewardItemsUIHelper";
import { Pagination } from "../../../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../../_metronic/_helpers";
import { useRewardItemsUIContext } from "./RewardItemsUIContext";
import { IconColumnFormatter, PointsColumnFormatter } from "./column-formatters/PointsColumnFormatter";

export function RewardItemsTable() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();
  const rewardItemsUIProps = useMemo(() => {
    return {
      ids: rewardItemsUIContext.ids,
      setIds: rewardItemsUIContext.setIds,
      queryParams: rewardItemsUIContext.queryParams,
      setQueryParams: rewardItemsUIContext.setQueryParams,
      rewardId: rewardItemsUIContext.rewardId,
      openEditRewardItemDialog: rewardItemsUIContext.openEditRewardItemDialog,
      openDeleteRewardItemDialog: rewardItemsUIContext.openDeleteRewardItemDialog,
    };
  }, [rewardItemsUIContext]);

  // Getting curret state of rewards list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.rewardItems }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    rewardItemsUIProps.setIds([]);
    dispatch(
      actions.fetchRewardItems(rewardItemsUIProps.queryParams, rewardItemsUIProps.rewardId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardItemsUIProps.queryParams, dispatch, rewardItemsUIProps.rewardId]);
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: false,
      sortCaret: sortCaret,
    },
    {
      dataField: "cost",
      text: "REWARD Amount",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "points",
      text: "Reward Cost",
      sort: true,
      sortCaret: sortCaret,

      formatter: PointsColumnFormatter,
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditRewardItemDialog: rewardItemsUIProps.openEditRewardItemDialog,
        openDeleteRewardItemDialog: rewardItemsUIProps.openDeleteRewardItemDialog,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: rewardItemsUIProps.queryParams.pageSize,
    page: rewardItemsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bordered={false}
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  rewardItemsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: rewardItemsUIProps.ids,
                  setIds: rewardItemsUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
