import axios from "axios";
import { config } from "../../../../../config/config";

export const CUSTOMERS_URL = "api/socialconfig";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findSocialconfig(admin,name) {
  return axios.get(`${config.API_URL}/api/setting/social/${admin}/${name}`);
}

export function updateSocialconfig(socialconfig) {
  
  return axios.put(`${config.API_URL}/api/setting/social/${socialconfig._id}`, socialconfig );
}

export function createSocialconfig(socialconfig) {


  return axios.post(`${config.API_URL}/api/setting/social/`, socialconfig);
}

