import * as requestFromServer from "./rewardItemsCrud";
import {rewardItemsSlice, callTypes} from "./rewardItemsSlice";

const {actions} = rewardItemsSlice;

export const fetchRewardItems = (queryParams,rewardId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findRewardItems(queryParams,rewardId)
    .then(response => {

      const { totalCount, entities } = response.data;
      dispatch(actions.rewardItemsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rewardItems";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchRewardItem = _id => dispatch => {
  if (!_id) {
    return dispatch(actions.rewardItemFetched({ rewardItemForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRewardItemById(_id)
    .then(response => {
      const rewardItem = response.data;
      dispatch(actions.rewardItemFetched({ rewardItemForEdit: rewardItem }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rewardItem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRewardItemDetails = _id => dispatch => {
  if (!_id) {
    return dispatch(actions.rewardItemDetailsFetched({ rewardItemDetails: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRewardItemDetailsById(_id)
    .then(response => {
      const rewardItem = response.data;
      dispatch(actions.rewardItemDetailsFetched({ rewardItemDetails: rewardItem }));
    })
    .catch(error => {
      error.clientMessage = "Can't load rewardItem details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRewardItem = _id => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRewardItem(_id)
    .then(response => {
      dispatch(actions.rewardItemDeleted({ _id }));
    })
    .catch(error => {
      error.clientMessage = "Can't cancel rewardItem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createRewardItem = rewardItemForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createRewardItem(rewardItemForCreation)
    .then(response => {
      const { rewardItem } = response.data;
      dispatch(actions.rewardItemCreated({ rewardItem }));
    })
    .catch(error => {
      error.clientMessage = "Can't create rewardItem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRewardItem = rewardItem => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateRewardItem(rewardItem)
    .then(() => {
      dispatch(actions.rewardItemUpdated({ rewardItem }));
    })
    .catch(error => {
      error.clientMessage = "Can't update rewardItem";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRewardItemsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRewardItems(ids, status)
    .then(() => {
      dispatch(actions.rewardItemsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update rewardItems status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRewardItems = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRewardItems(ids)
    .then(() => {
      dispatch(actions.rewardItemsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete rewardItems";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
