/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../../_redux/countries/countriesActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../../_metronic/_partials/controls";
import { Rewards } from "../country-rewards/Rewards";
import { RewardsUIProvider } from "../country-rewards/RewardsUIContext";
import { useSubheader } from "../../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";



export function CountryEdit({
  history,
  match: {
    params: { _id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();


  // Tabs
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, countryForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.countries.actionsLoading,
      countryForEdit: state.countries.countryForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchCountry(_id));
  }, [_id, dispatch]);

  useEffect(() => {
    let _title = _id ? "" : "";
    if (countryForEdit && _id) {
      _title = `Add Rewards to '${countryForEdit.fullName}'`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countryForEdit, _id]);



  const backToCountriesList = () => {
    history.push(`/dash/rewards/countries`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToCountriesList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {_id && (
          <RewardsUIProvider history={history} currentCountryId={_id}>
            <Rewards />
          </RewardsUIProvider>
        )}
      </CardBody>
    </Card>
  );
}
