import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import Table from 'react-bootstrap/Table'

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/countries/countriesActions";
import { useCountriesUIContext } from "../CountriesUIContext";

export function CountryDeleteDialog({ id, show, onHide }) {
  // Countries UI Context
  const countriesUIContext = useCountriesUIContext();
  const countriesUIProps = useMemo(() => {
    return {
      setIds: countriesUIContext.setIds,
      queryParams: countriesUIContext.queryParams,
    };
  }, [countriesUIContext]);

  // Countries Redux state
  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.countries.actionsLoading }),
    shallowEqual
  );

  // if !id we should close modal
  useEffect(() => {
    if (!id) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // looking for loading/dispatch
  useEffect(() => {}, [isLoading, dispatch]);

  const deleteCountry = () => {
    // server request for deleting country by id
    dispatch(actions.deleteCountry(id)).then(() => {
      // refresh list after deletion
      dispatch(actions.fetchCountries(countriesUIProps.queryParams));
      // clear selections list
      countriesUIProps.setIds([]);
      // closing delete modal
      onHide();
    });
  };

  return (
    <Modal
    show={show}
    onHide={onHide}
    aria-labelledby="example-modal-sizes-title-lg"
  >
    {isLoading && <ModalProgressBar variant="query" />}
    <Modal.Header closeButton>
      <Modal.Title id="example-modal-sizes-title-lg">
        Delete Country
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {!isLoading && (
        <span>Would you like to delete this country?</span>
      )}
      {isLoading && <span>Country is delrting...</span>}
    </Modal.Body>
    <Modal.Footer>
      <div>
        <button
          type="button"
          onClick={onHide}
          className="btn btn-light btn-elevate"
        >
          close
        </button>
        <> </>
        <button
          type="button"
          onClick={deleteCountry}
          className="btn btn-danger btn-elevate"
        >
          Delete Country
        </button>
      </div>
    </Modal.Footer>
  </Modal>
  );
}
