import React, { useEffect, useState } from "react";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/setting/settingActions";
import { TextArea } from "../../../../../../_metronic/_partials/controls/forms/TextArea";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Card } from "react-bootstrap";

const MailgunConfig = {
  _id: undefined,
  mailgunDomain: "",
  apiKey: "",
  fromEmail: "",
  supportEmail: "",
};
const MailgunConfigEditSchema = Yup.object().shape({
  mailgunDomain: Yup.string()
    .min(1, "Minimum 1 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Mailgun App Domain is required"),
  apiKey: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 250 symbols")
    .required("Mailgun Api Key is required"),
  fromEmail: Yup.string()
    .email("Invalid email format")
    .required("From Email is required"),
  supportEmail: Yup.string()
    .email("Invalid email format")
    .required("Support Email is required"),
});
function MailgunEditForm({ name }) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { actionsLoading, mailgunconfigForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.setting.actionsLoading,
      mailgunconfigForEdit: state.setting.mailgunconfigForEdit,
    }),
    shallowEqual
  );


  useEffect(() => {
    // server call for getting Network by id
    dispatch(actions.fetchMailgunConfig(user._id));
  }, [user, dispatch]);
  const saveMailgunConfig = (mailgunconfig) => {
    if (!mailgunconfig._id) {
      mailgunconfig.adminId = user._id;
      mailgunconfig.name = name;
      // server request for creating network
      dispatch(actions.createMailgunConfig(mailgunconfig)).then();
    } else {
      // server request for updating network
      dispatch(actions.updateMailgunConfig(mailgunconfig)).then();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={mailgunconfigForEdit || MailgunConfig}
      validationSchema={MailgunConfigEditSchema}
      onSubmit={(values) => {
        
        saveMailgunConfig(values);
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Form className="form form-label-right ">
            <Card.Header>
              <div className="form-group row">
                {/* Product Name */}
                <div className="col-lg-10">
                  <Field
                    name="apiKey"
                    component={Input}
                    placeholder="Mailgun Api Key"
                    label="Mailgun Api Key"
                  />
                </div>
                {/* Moto Name */}
                <div className="col-lg-10">
                  <Field
                    name="mailgunDomain"
                    component={Input}
                    placeholder="Mailgun App Domain"
                    label="Mailgun App Domain"
                  />
                </div>
              </div>
              <div className="form-group row">
                {/* Domain Name */}
                <div className="col-lg-10">
                  <Field
                    name="fromEmail"
                    component={Input}
                    placeholder="From Email"
                    label="From Email"
                  />
                </div>
                {/* Domain Shortcut */}
                <div className="col-lg-10">
                  <Field
                    name="supportEmail"
                    component={Input}
                    placeholder="Support Email"
                    label="Support Email"
                  />
                </div>
              </div>
            </Card.Header>
              
          </Form>
          <div className="form-group row">
            {/* Endpoint Name */}
            <div className="col-lg-6">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              {actionsLoading && (
                <div
                  style={{ float: "right" }}
                  className="overlay-layer bg-transparent"
                >
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default MailgunEditForm;
