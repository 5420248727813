import * as requestFromServer from "./rewardsCrud";
import {rewardsSlice, callTypes} from "./rewardsSlice";

const {actions} = rewardsSlice;

export const fetchRewards = (queryParams,countryId) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findRewards(queryParams,countryId)
    .then(response => {

      const { totalCount, entities } = response.data;
      dispatch(actions.rewardsFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find rewards";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchReward = _id => dispatch => {
  if (!_id) {
    return dispatch(actions.rewardFetched({ rewardForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRewardById(_id)
    .then(response => {
      const reward = response.data;
      dispatch(actions.rewardFetched({ rewardForEdit: reward }));
    })
    .catch(error => {
      error.clientMessage = "Can't find reward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchRewardDetails = _id => dispatch => {
  if (!_id) {
    return dispatch(actions.rewardDetailsFetched({ rewardDetails: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getRewardDetailsById(_id)
    .then(response => {
      const reward = response.data;
      dispatch(actions.rewardDetailsFetched({ rewardDetails: reward }));
    })
    .catch(error => {
      error.clientMessage = "Can't load reward details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteReward = _id => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteReward(_id)
    .then(response => {
      dispatch(actions.rewardDeleted({ _id }));
    })
    .catch(error => {
      error.clientMessage = "Can't cancel reward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createReward = rewardForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createReward(rewardForCreation)
    .then(response => {
      const { reward } = response.data;
      dispatch(actions.rewardCreated({ reward }));
    })
    .catch(error => {
      error.clientMessage = "Can't create reward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateReward = reward => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateReward(reward)
    .then(() => {
      dispatch(actions.rewardUpdated({ reward }));
    })
    .catch(error => {
      error.clientMessage = "Can't update reward";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateRewardsStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForRewards(ids, status)
    .then(() => {
      dispatch(actions.rewardsStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update rewards status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteRewards = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteRewards(ids)
    .then(() => {
      dispatch(actions.rewardsDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete rewards";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
