import ClassicEditor from "@ckeditor/ckeditor5-build-classic"
import CKEditor from "@ckeditor/ckeditor5-react"
import React from "react"

import './ckeditor.css'


function Ckeditor(props) {
    return (
        <div>
            <div>
                <CKEditor 
                    editor={ClassicEditor}
                    data={props.data}
                    onChange={props.onChange}
                />
            </div>
            
        </div>
    )
}

export default Ckeditor;