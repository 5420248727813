import {createSlice} from "@reduxjs/toolkit";

const initialRewardsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  rewardForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const rewardsSlice = createSlice({
  name: "rewards",
  initialState: initialRewardsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {


      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // getRewardById
    rewardFetched: (state, action) => {
      state.actionsLoading = false;
      state.rewardForEdit = action.payload.rewardForEdit;
      state.error = null;
    },
    // getRewardDetailsById
    rewardDetailsFetched: (state, action) => {
      state.actionsLoading = false;
      state.rewardDetails = action.payload.rewardDetails;
      state.error = null;
    },
    // findRewards
    rewardsFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    // createReward
    rewardCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.entities.push(action.payload.reward);
    },
    // updateReward
    rewardUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.map(entity => {
        if (entity._id === action.payload.reward._id) {
          return action.payload.reward;
        }
        return entity;
      });
    },
    // deleteReward
    rewardDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      // eslint-disable-next-line no-self-assign
      state.entities = state.entities;
    },
    // deleteRewards
    rewardsDeleted: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.entities = state.entities.filter(
        el => !action.payload.ids.includes(el.id)
      );
    },
    // rewardsUpdateState
    rewardsStatusUpdated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      const { ids, status } = action.payload;
      state.entities = state.entities.map(entity => {
        if (ids.findIndex(id => id === entity._id) > -1) {
          entity.status = status;
        }
        return entity;
      });
    }
  }
});
