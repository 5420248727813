import axios from "axios";
import { config } from "../../../../config/config";

export const LOGIN_URL = `${config.API_URL}/api/users/login`;
export const REGISTER_URL = `${config.API_URL}/api/users/register`;
export const REQUEST_PASSWORD_URL = `${config.API_URL}/api/users/auth/recover`;

export const ME_URL = `${config.API_URL}/api/profile/me`;

export const RESET_URL= `${config.API_URL}/api/users/auth/reset/`;


axios.defaults.headers.post["Content-Type"] = "application/json";

export function login(email, password) {
  return axios.post(
    LOGIN_URL,
    JSON.stringify({ username: email, password: password })
  );
}

export function register(email, fullname, password) {
  return axios.post(
    REGISTER_URL,
    JSON.stringify({
      username: email,
      password: password,
      confirmPassword: password,
      fullName: fullname,
    })
  );
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function verifyToken(token) {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(RESET_URL+token);
}

export function resetPassword(token,password,confirmPassword) {
  return axios.post(RESET_URL+token, { password,confirmPassword });
}
