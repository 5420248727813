import axios from "axios";
import { config } from "../../../../../config/config";

export const CUSTOMERS_URL = "api/rewards";

axios.defaults.headers.post["Content-Type"] = "application/json";

// CREATE =>  POST: add a new reward to the server
export function createReward(reward) {
  var bodyFormData = new FormData();
  bodyFormData.append("name", reward.name);
  bodyFormData.append("order", reward.order);
  bodyFormData.append("file", reward.file);

  return axios.post(
    `${config.API_URL}/api/reward/rewards/add/${reward.countryId}`,
    bodyFormData
  );
}

// READ
export function getAllRewards() {
  return axios.get(CUSTOMERS_URL);
}

export function getRewardById(rewardId) {
  return axios.get(`${config.API_URL}/api/reward/rewards/${rewardId}`);
}

export function getRewardDetailsById(rewardId) {
  return axios.get(`${config.API_URL}/api/users/details/${rewardId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRewards(queryParams, countryId) {
  return axios.post(
    `${config.API_URL}/api/reward/rewards/${countryId}?group=false`,
    JSON.stringify(queryParams)
  );
}

// UPDATE => PUT: update the reward on the server
export function updateReward(reward) {
  var bodyFormData = new FormData();
  bodyFormData.append("name", reward.name);
  bodyFormData.append("order", reward.order);
  bodyFormData.append("file", reward.file);
  return axios.put(`${config.API_URL}/api/reward/rewards/${reward._id}`, bodyFormData);
}

// UPDATE Status
export function updateStatusForRewards(ids, status) {
  return axios.put(`${config.API_URL}/api/reward/state/ids`, {
    ids,
    status,
  });
}

// DELETE => delete the reward from the server
export function deleteReward(rewardId) {
  return axios.delete(`${config.API_URL}/api/reward/rewards/${rewardId}`);
}

// DELETE Rewards by ids
export function deleteRewards(ids) {
  return axios.post(`${config.API_URL}/api/reward/deleteRewards`, { ids });
}
