/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid,jsx-a11y/role-supports-aria-props */
import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { shallowEqual, useSelector } from "react-redux";
import * as actions from "../../../../_redux/rewards/rewardsActions";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../../../../_metronic/_partials/controls";
import { RewardItems } from "../country-rewardItems/RewardItems";
import { RewardItemsUIProvider } from "../country-rewardItems/RewardItemsUIContext";
import { useSubheader } from "../../../../../../../_metronic/layout";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";

const initReward = {
  id: undefined,
  model: "",
  manufacture: "Pontiac",
  modelYear: 2020,
  mileage: 0,
  description: "",
  color: "Red",
  price: 10000,
  condition: 1,
  status: 0,
  VINCode: "",
};

export function RewardEdit({
  history,
  match: {
    params: { _id },
  },
}) {
  // Subheader
  const suhbeader = useSubheader();


  // Tabs
  const [title, setTitle] = useState("");
  const dispatch = useDispatch();
  // const layoutDispatch = useContext(LayoutContext.Dispatch);
  const { actionsLoading, rewardForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.rewards.actionsLoading,
      rewardForEdit: state.rewards.rewardForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    dispatch(actions.fetchReward(_id));
  }, [_id, dispatch]);

  useEffect(() => {
    let _title = _id ? "" : "";
    if (rewardForEdit && _id) {
      _title = `Add Rewards to '${rewardForEdit.name}'`;
    }

    setTitle(_title);
    suhbeader.setTitle(_title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardForEdit, _id]);

  const saveReward = (values) => {
    if (!_id) {
      dispatch(actions.createReward(values)).then(() => backToRewardsList());
    } else {
      dispatch(actions.updateReward(values)).then(() => backToRewardsList());
    }
  };

  const btnRef = useRef();
  const saveRewardClick = () => {
    if (btnRef && btnRef.current) {
      btnRef.current.click();
    }
  };

  const backToRewardsList = () => {
    history.push(`/dash/rewards/rewards`);
  };

  return (
    <Card>
      {actionsLoading && <ModalProgressBar />}
      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={backToRewardsList}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {_id && (
          <RewardItemsUIProvider currentRewardId={_id}>
            <RewardItems />
          </RewardItemsUIProvider>
        )}
      </CardBody>
    </Card>
  );
}
