// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as actions from "../../../../_redux/rewards/rewardsActions";
import { ActionsColumnFormatter } from "./column-formatters/ActionsColumnFormatter";
import * as uiHelpers from "./RewardsUIHelper";
import { Pagination } from "../../../../../../../_metronic/_partials/controls";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
} from "../../../../../../../_metronic/_helpers";
import { useRewardsUIContext } from "./RewardsUIContext";
import { IconColumnFormatter } from "./column-formatters/IconColumnFormatter";

export function RewardsTable() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return {
      ids: rewardsUIContext.ids,
      setIds: rewardsUIContext.setIds,
      queryParams: rewardsUIContext.queryParams,
      setQueryParams: rewardsUIContext.setQueryParams,
      countryId: rewardsUIContext.countryId,
      openEditRewardDialog: rewardsUIContext.openEditRewardDialog,
      openAddItemstoRewardPage: rewardsUIContext.openAddItemstoRewardPage,
      openDeleteRewardDialog: rewardsUIContext.openDeleteRewardDialog,
    };
  }, [rewardsUIContext]);

  // Getting curret state of countrys list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.rewards }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;
  const dispatch = useDispatch();
  useEffect(() => {
    rewardsUIProps.setIds([]);
    dispatch(
      actions.fetchRewards(rewardsUIProps.queryParams, rewardsUIProps.countryId)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardsUIProps.queryParams, dispatch, rewardsUIProps.countryId]);
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "name",
      text: "REWARD NAME",
      sort: true,
      sortCaret: sortCaret,
    },
    {
      dataField: "iconUrl",
      text: "ICON",
      sort: false,
      formatter: IconColumnFormatter,
    },
    {
      dataField: "order",
      text: "PRIORITY",
      sort: false,
      
    },
    {
      dataField: "action",
      text: "Actions",
      formatter: ActionsColumnFormatter,
      formatExtraData: {
        openEditRewardDialog: rewardsUIProps.openEditRewardDialog,
        openDeleteRewardDialog: rewardsUIProps.openDeleteRewardDialog,
        openAddItemstoRewardPage: rewardsUIProps.openAddItemstoRewardPage,
      },
      classes: "text-right pr-0",
      headerClasses: "text-right pr-3",
      style: {
        minWidth: "100px",
      },
    },
  ];

  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: rewardsUIProps.queryParams.pageSize,
    page: rewardsUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                classes="table table-head-custom table-vertical-center"
                bordered={false}
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  rewardsUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: rewardsUIProps.ids,
                  setIds: rewardsUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
