import * as requestFromServer from "./offersCrud";
import {offersSlice, callTypes} from "./offersSlice";

const {actions} = offersSlice;

export const fetchOffers = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return requestFromServer
    .findOffers(queryParams)
    .then(response => {

      const { totalCount, entities } = response.data;
      dispatch(actions.offersFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find offers";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const fetchOffer = _id => dispatch => {
  if (!_id) {
    return dispatch(actions.offerFetched({ offerForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOfferById(_id)
    .then(response => {
      const offer = response.data;
      dispatch(actions.offerFetched({ offerForEdit: offer }));
    })
    .catch(error => {
      error.clientMessage = "Can't find offer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const fetchOfferDetails = _id => dispatch => {
  if (!_id) {
    return dispatch(actions.offerDetailsFetched({ offerDetails: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .getOfferDetailsById(_id)
    .then(response => {
      const offer = response.data;
      dispatch(actions.offerDetailsFetched({ offerDetails: offer }));
    })
    .catch(error => {
      error.clientMessage = "Can't load offer details";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOffer = _id => dispatch => {

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOffer(_id)
    .then(response => {
      dispatch(actions.offerDeleted({ _id }));
    })
    .catch(error => {
      error.clientMessage = "Can't cancel offer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const createOffer = offerForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createOffer(offerForCreation)
    .then(response => {
      const { offer } = response.data;
      dispatch(actions.offerCreated({ offer }));
    })
    .catch(error => {
      error.clientMessage = "Can't create offer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOffer = offer => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateOffer(offer)
    .then(() => {
      dispatch(actions.offerUpdated({ offer }));
    })
    .catch(error => {
      error.clientMessage = "Can't update offer";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateOffersStatus = (ids, status) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateStatusForOffers(ids, status)
    .then(() => {
      dispatch(actions.offersStatusUpdated({ ids, status }));
    })
    .catch(error => {
      error.clientMessage = "Can't update offers status";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const deleteOffers = ids => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .deleteOffers(ids)
    .then(() => {
      dispatch(actions.offersDeleted({ ids }));
    })
    .catch(error => {
      error.clientMessage = "Can't delete offers";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
