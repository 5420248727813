import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {customersSlice} from "../app/modules/Dash/_redux/customers/customersSlice";
import {redeemsSlice} from "../app/modules/Dash/_redux/redeems/redeemsSlice";
import {offersSlice} from "../app/modules/Dash/_redux/offers/offersSlice";
import {countriesSlice} from "../app/modules/Dash/_redux/countries/countriesSlice";
import {rewardsSlice} from "../app/modules/Dash/_redux/rewards/rewardsSlice";
import {rewardItemsSlice} from "../app/modules/Dash/_redux/rewardItems/rewardItemsSlice";
import {dashboardSlice} from "../app/modules/Dash/_redux/dashboard/dashboardSlice";
import {giveawaySlice} from "../app/modules/Dash/_redux/giveaway/giveawaySlice";
import { winnerSlice } from "../app/modules/Dash/_redux/winner/winnerSlice";
import { networkSlice } from "../app/modules/Dash/_redux/network/networkSlice";
import { settingSlice } from "../app/modules/Dash/_redux/setting/settingSlice";
import { socialconfigSlice } from "../app/modules/Dash/_redux/socialconfig/socialconfigSlice";
import { settingconfigSlice } from "../app/modules/Dash/_redux/settingconfig/settingconfigSlice";
import {notificationSlice} from "../app/modules/Dash/_redux/notification/notificationSlice";
import { nativeAdsSlice } from "../app/modules/Dash/_redux/nativeAds/nativeAdsSlice";




export const rootReducer = combineReducers({
  auth: auth.reducer,
  customers: customersSlice.reducer,
  redeems: redeemsSlice.reducer,
  offers: offersSlice.reducer,
  countries: countriesSlice.reducer,
  rewards:rewardsSlice.reducer,
  rewardItems:rewardItemsSlice.reducer,
  dashboard:dashboardSlice.reducer,
  giveaway:giveawaySlice.reducer,
  winner:winnerSlice.reducer,
  network: networkSlice.reducer,
  setting: settingSlice.reducer,
  socialconfig: socialconfigSlice.reducer,
  settingconfig: settingconfigSlice.reducer,
  notification:notificationSlice.reducer,
  nativeAds: nativeAdsSlice.reducer,

});

export function* rootSaga() {
  yield all([auth.saga()]);
}
