import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";
import { RedeemStatusCssClasses } from "../RedeemsUIHelpers";
import { useRedeemsUIContext } from "../RedeemsUIContext";

const selectedRedeems = (entities, ids) => {
  const _redeems = [];
  ids.forEach((id) => {
    const redeem = entities.find((el) => el.id === id);
    if (redeem) {
      _redeems.push(redeem);
    }
  });
  return _redeems;
};

export function RedeemsFetchDialog({ show, onHide }) {
  // Redeems UI Context
  const redeemsUIContext = useRedeemsUIContext();
  const redeemsUIProps = useMemo(() => {
    return {
      ids: redeemsUIContext.ids,
    };
  }, [redeemsUIContext]);

  // Redeems Redux state
  const { redeems } = useSelector(
    (state) => ({
      redeems: selectedRedeems(
        state.redeems.entities,
        redeemsUIProps.ids
      ),
    }),
    shallowEqual
  );

  // if redeems weren't selected we should close modal
  useEffect(() => {
    if (!redeemsUIProps.ids || redeemsUIProps.ids.length === 0) {
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redeemsUIProps.ids]);

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          Fetch selected elements
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="timeline timeline-5 mt-3">
          {redeems.map((redeem) => (
            <div className="timeline-item align-items-start" key={`id${redeem.id}`}>
              <div className="timeline-label font-weight-bolder text-dark-75 font-size-lg text-right pr-3" />
              <div className="timeline-badge">
                <i
                  className={`fa fa-genderless text-${
                    RedeemStatusCssClasses[redeem.status]
                  } icon-xxl`}
                />
              </div>
              <div className="timeline-content text-dark-50 mr-5">
                <span
                    className={`label label-lg label-light-${
                      RedeemStatusCssClasses[redeem.status]
                    } label-inline`}
                  >
                    ID: {redeem.id}
                </span>
                <span className="ml-3">{redeem.lastName}, {redeem.firstName}</span>                
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-primary btn-elevate"
          >
            Ok
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
