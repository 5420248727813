import React, { useEffect,useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/network/networkActions";
import * as actions1 from "../../../_redux/nativeAds/nativeAdsActions";

import { useSubheader } from "../../../../../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
  ModalProgressBar,
} from "../../../../../../_metronic/_partials/controls";

import { NetworkEditForm } from "./HangmyadsEditForm";
import { OgadsEditForm } from "./OgadsEditForm";
import { HangMyAdsOWEditForm } from "./offerwalls/HangmyadsowEditForm";
import { AdgateMediaOwForm } from "./offerwalls/AdgateMediaOwForm";
import { IpDataEditForm } from "./IpdataEditForm";
import { ApplovinForm } from "./nativeAds/ApplovinForm";
import { AdgemOwForm } from "./offerwalls/AdgemOwForm";
import CpxResearchOwForm from "./offerwalls/CpxResearchOwForm";
import LootablyOwForm from "./offerwalls/LootablyOwForm";
import BitlabsOwForm from "./offerwalls/BitlabsOwForm";

export function NetworkEditDialog({
  history,
  match: {
    params: { name },
  },
}) {
  // Network UI Context
  const [title, setTitle] = useState("");
  const suhbeader = useSubheader();
  
const initNetwork= {
  _id: undefined,
  appId: "",
  appToken: "",
  postbackSecret: "",
  provision: 0,
  iosEnable: 0,
  androidEnable: 0,
};

const nativeAdsInit= {
  _id: undefined,
  owClick: 0,
  navClick: 0,
  owEnable: 0,
  openEnable: 0,
  navClickEnable: 0,
  bannerEnable: 0
};
  // Network Redux state
  const dispatch = useDispatch();
  const { actionsLoading, networkForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.network.actionsLoading,
      networkForEdit: state.network.networkForEdit,
    }),
    shallowEqual
  );

  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    // server call for getting Network by id
    if(name!=="applovin") {
      dispatch(actions.fetchNetwork(name,user._id));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, dispatch]);

  useEffect(() => {
    let _title = name ? "Network Setting" : "Network Setting ";
    if (networkForEdit && name) {
      _title = `${networkForEdit.network.toUpperCase()} Setting`;
    }

    setTitle(_title);
    suhbeader.setTitle("Network Setting");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkForEdit, name]);

  // server request for saving network
  const saveNetwork = (network) => {

    if (!network._id) {
      network.adminId = user._id;
      network.network=name;
      // server request for creating network
      dispatch(actions.createNetwork(network)).then();
    } else {
      // server request for updating network
      dispatch(actions.updateNetwork(network)).then(()=>backToNetworkList());
    }
  };

  ////////////////////////////Native Ads Redux ////////////////////////
  const { actionsLoading1, nativeAdsForEdit } = useSelector(
    (state) => ({
      actionsLoading1: state.nativeAds.actionsLoading,
      nativeAdsForEdit: state.nativeAds.nativeAdsForEdit,
    }),
    shallowEqual
  );


  useEffect(() => {
    // server call for getting Network by id
    dispatch(actions1.fetchNativeAds(name,user._id));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name, dispatch]);

  useEffect(() => {
    let _title = name ? "Network Setting" : "Network Setting ";
    if (nativeAdsForEdit && name) {
      _title = `${nativeAdsForEdit.network.toUpperCase()} Setting`;
    }

    setTitle(_title);
    suhbeader.setTitle("Network Setting");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nativeAdsForEdit, name]);

  // server request for saving network
  const saveNativeAds = (nativeAds) => {

    if (!nativeAds._id) {
      nativeAds.adminId = user._id;
      nativeAds.network=name;
      // server request for creating network
      dispatch(actions1.createNativeAds(nativeAds)).then();
    } else {
      // server request for updating network
      dispatch(actions1.updateNativeAds(nativeAds)).then(()=>backToNetworkList());
    }
  };
  ////////////////////////////////////////////////////////////////////

  const backToNetworkList = () => {
    history.push(`/dash/network`);
  };

  return (
    <Card
    >
    {actionsLoading && <ModalProgressBar />}
    {actionsLoading1 && <ModalProgressBar />}

      <CardHeader title={title}>
        <CardHeaderToolbar>
          <button
            type="button"
            className="btn btn-light"
            onClick={backToNetworkList}
          >
            <i className="fa fa-arrow-left"></i>
            Back
          </button>
          {`  `}
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
     {name==="hangmyads" && <NetworkEditForm 
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="ogads" && <OgadsEditForm 
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
      {name==="hangmyadsow" && <HangMyAdsOWEditForm 
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="adgateow" && <AdgateMediaOwForm 
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="adgemow" && <AdgemOwForm
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
      {name==="cpxresearch" && <CpxResearchOwForm
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="lootably" && <LootablyOwForm
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="bitlabs" && <BitlabsOwForm
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="ipdata" && <IpDataEditForm 
       network={networkForEdit || initNetwork}
       actionsLoading={actionsLoading}
       saveNetwork={saveNetwork}
       history={history}
     />}
     {name==="applovin" && <ApplovinForm 
       network={nativeAdsForEdit || nativeAdsInit}
       actionsLoading={actionsLoading}
       saveNetwork={saveNativeAds}
       history={history}
     />}
      </CardBody>
      
    </Card>
  );
}
