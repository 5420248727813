// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/offers/offersActions";
import {
  getSelectRow,
  getHandlerTableChange,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
  sortCaret,
  headerSortingClasses,
} from "../../../../../../_metronic/_helpers";
import * as uiHelpers from "../OffersUIHelpers";
import * as columnFormatters from "./column-formatters";
import { Pagination } from "../../../../../../_metronic/_partials/controls";
import { useOffersUIContext } from "../OffersUIContext";

export function OffersTable() {
  // Offers UI Context
  const offersUIContext = useOffersUIContext();
  const offersUIProps = useMemo(() => {
    return {
      ids: offersUIContext.ids,
      setIds: offersUIContext.setIds,
      queryParams: offersUIContext.queryParams,
      setQueryParams: offersUIContext.setQueryParams,
      openEditOfferDialog: offersUIContext.openEditOfferDialog,
      openDeleteOfferDialog: offersUIContext.openDeleteOfferDialog,
      openFetchOffersDetailsDialog: offersUIContext.openFetchOffersDetailsDialog
    };
  }, [offersUIContext]);

  // Getting curret state of offers list from store (Redux)
  const { currentState } = useSelector(
    (state) => ({ currentState: state.offers }),
    shallowEqual
  );
  const { totalCount, entities, listLoading } = currentState;

  // Offers Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    offersUIProps.setIds([]);
    // server call by queryParams
    dispatch(actions.fetchOffers(offersUIProps.queryParams));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offersUIProps.queryParams, dispatch]);
  // Table columns
  const columns = [
    {
      dataField: "_id",
      text: "ID",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "offerName",
      text: "Offer Name",
      sort: false,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "network",
      text: "AD NETWORK",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        maxWidth: "250px",
      },
    },
    {
      dataField: "userId",
      text: "USER",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      style: {
        maxWidth: "250px",
      },
    },

    {
      dataField: "payout",
      text: "OFFER PAYOUT",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.PayoutColumnFormatter,
    },
    {
      dataField: "ip",
      text: "IP ADDRESS",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "geo",
      text: "GEO",
      sort: false,
      sortCaret: sortCaret,
      formatter: columnFormatters.GeoColumnFormatter,

    },
    {
      dataField: "date",
      text: "CREATED AT",
      sort: true,
      sortCaret: sortCaret,
      formatter: columnFormatters.TypeColumnFormatter,
      style: {
        minWidth: "120px",
      },
     
    },  
  ];
  // Table pagination properties
  const paginationOptions = {
    custom: true,
    totalSize: totalCount,
    sizePerPageList: uiHelpers.sizePerPageList,
    sizePerPage: offersUIProps.queryParams.pageSize,
    page: offersUIProps.queryParams.pageNumber,
  };
  return (
    <>
      <PaginationProvider pagination={paginationFactory(paginationOptions)}>
        {({ paginationProps, paginationTableProps }) => {
          return (
            <Pagination
              isLoading={listLoading}
              paginationProps={paginationProps}
            >
              <BootstrapTable
                wrapperClasses="table-responsive"
                bordered={false}
                classes="table table-head-custom table-vertical-center"
                bootstrap4
                remote
                keyField="_id"
                data={entities === null ? [] : entities}
                columns={columns}
                defaultSorted={uiHelpers.defaultSorted}
                onTableChange={getHandlerTableChange(
                  offersUIProps.setQueryParams
                )}
                selectRow={getSelectRow({
                  entities,
                  ids: offersUIProps.ids,
                  setIds: offersUIProps.setIds,
                })}
                {...paginationTableProps}
              >
                <PleaseWaitMessage entities={entities} />
                <NoRecordsFoundMessage entities={entities} />
              </BootstrapTable>
            </Pagination>
          );
        }}
      </PaginationProvider>
    </>
  );
}
