import * as requestFromServer from "./nativeAdsCrud";
import {nativeAdsSlice, callTypes} from "./nativeAdsSlice";

const {actions} = nativeAdsSlice;

export const fetchNativeAds = (name,admin) => dispatch => {
  if (!name) {
    return dispatch(actions.nativeAdsFetched({ nativeAdsForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findNativeAds(name,admin)
    .then(response => {
      const nativeAds = response.data;
      dispatch(actions.nativeAdsFetched({ nativeAdsForEdit: nativeAds }));
    })
    .catch(error => {
      error.clientMessage = "Can't find nativeAds";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.nativeAdsFetched({ nativeAdsForEdit: undefined }));
    });
};

export const createNativeAds = nativeAdsForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createNativeAds(nativeAdsForCreation)
    .then(response => {
      const { nativeAds } = response.data;
      dispatch(actions.nativeAdsCreated(nativeAds ));
    })
    .catch(error => {
      error.clientMessage = "Can't create nativeAds";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateNativeAds = nativeAds => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateNativeAds(nativeAds)
    .then(() => {
      dispatch(actions.nativeAdsUpdated(nativeAds ));
    })
    .catch(error => {
      error.clientMessage = "Can't update nativeAds";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
