import axios from "axios";
import { config } from "../../../../../config/config";

export const CUSTOMERS_URL = "api/countries";

axios.defaults.headers.post["Content-Type"] = "application/json";


// CREATE =>  POST: add a new country to the server
export function createCountry(country) {
  return axios.post('https://dashboard-dot-appnabil2021.uc.r.appspot.com/api/reward/countries',  country );
}

// READ
export function getAllCountries() {
  return axios.get(CUSTOMERS_URL);
}

export function getCountryById(countryId) {
  return axios.get(`${config.API_URL}/api/reward/countries/${countryId}`);
}

export function getCountryDetailsById(countryId) {
  return axios.get(`${config.API_URL}/api/users/details/${countryId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findCountries(queryParams) {
  return axios.post(`${config.API_URL}/api/reward/countries/pagination?group=false`, JSON.stringify(queryParams));
}

// UPDATE => PUT: update the country on the server
export function updateCountry(country) {
  return axios.put(`${config.API_URL}/api/reward/countries/${country._id}`, country );
}

// UPDATE Status
export function updateStatusForCountries(ids, status) {
  return axios.put(`${config.API_URL}/api/country/state/ids`, {
    ids,
    status
  });
}

// DELETE => delete the country from the server
export function deleteCountry(countryId) {
  return axios.delete(`${config.API_URL}/api/reward/countries/${countryId}`);
}

// DELETE Countries by ids
export function deleteCountries(ids) {
  return axios.post(`${config.API_URL}/api/reward/countries/deleteCountries`, { ids });
}
