/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../../_metronic/_partials/controls";

export function RewardEditDialogHeader({ id }) {
  const [title, setTitle] = useState("");
  // Rewards Redux state
  const { rewardForEdit, actionsLoading } = useSelector(
    (state) => ({
      rewardForEdit: state.rewards.rewardForEdit,
      actionsLoading: state.rewards.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? "" : "New Reward";
    if (rewardForEdit && id) {
      _title = "Edit reward "+rewardForEdit.name;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [rewardForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
