// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
  Select,
} from "../../../../../../../_metronic/_partials/controls";

import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import { countriesList } from "../CountriesUIHelpers";


// Validation schema
const CountryEditSchema = Yup.object().shape({
  priority: Yup.number()
    .required("Priority is required"),
});

export function CountryEditForm({
  saveCountry,
  country,
  actionsLoading,
  onHide,
}) {


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={country}
        validationSchema={CountryEditSchema}
        onSubmit={(values) => {
          values.code=countriesList.find(x=> x.name === values.fullName).alpha2.toUpperCase();
          saveCountry(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <ModalProgressBar />
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  {/* Shared Key */}
                <div className="col-lg-6">
                <Select name="fullName" label="Country Name">
                    {countriesList.map((country) => (
                      <option key={country.id} value={country.name}>
                        {country.name}
                      </option>
                    ))}
                  </Select>
                  </div>

                  {/* add to credit */}
                  <div className="col-lg-6">
                    <Field
                      name="priority"
                      component={Input}
                      placeholder="Priority"
                      label="Priority (Order of display)"
                      type="number"
                    />
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
