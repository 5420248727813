import * as requestFromServer from "./socialconfigCrud";
import {socialconfigSlice, callTypes} from "./socialconfigSlice";

const {actions} = socialconfigSlice;

export const fetchSocialconfig = (admin) => dispatch => {
  if (!admin) {
    return dispatch(actions.socialconfigFetched({ socialconfigForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer 
    .findSocialconfig(admin,"facebook")
    .then(response => {
      const socialconfig = response.data;
      dispatch(actions.socialconfigFetched({ socialconfigForEdit: socialconfig }));
    })
    .catch(error => {
      error.clientMessage = "Can't find socialconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.socialconfigFetched({ socialconfigForEdit: undefined }));
    });
};

export const fetchGoogleconfig = (admin) => dispatch => {
  if (!admin) {
    return dispatch(actions.googleconfigFetched({ googleconfigForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer 
    .findSocialconfig(admin,"google")
    .then(response => {
      const socialconfig = response.data;
      dispatch(actions.googleconfigFetched({ googleconfigForEdit: socialconfig }));
    })
    .catch(error => {
      error.clientMessage = "Can't find socialconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.googleconfigFetched({ googleconfigForEdit: undefined }));
    });
};

export const fetchTwitterconfig = (admin) => dispatch => {
  if (!admin) {
    return dispatch(actions.twitterconfigFetched({ twitterconfigForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer 
    .findSocialconfig(admin,"twitter")
    .then(response => {
      const socialconfig = response.data;
      dispatch(actions.twitterconfigFetched({ twitterconfigForEdit: socialconfig }));
    })
    .catch(error => {
      error.clientMessage = "Can't find socialconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.twitterconfigFetched({ twitterconfigForEdit: undefined }));
    });
};

export const createSocialconfig = socialconfigForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createSocialconfig(socialconfigForCreation)
    .then(response => {
      const { socialconfig } = response.data;
      dispatch(actions.socialconfigCreated(socialconfig ));
    })
    .catch(error => {
      error.clientMessage = "Can't create socialconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateSocialconfig = socialconfig => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateSocialconfig(socialconfig)
    .then(() => {
      dispatch(actions.socialconfigUpdated(socialconfig ));
    })
    .catch(error => {
      error.clientMessage = "Can't update socialconfig";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
