import {createSlice} from "@reduxjs/toolkit";

const initialNetworkState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  networkForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const networkSlice = createSlice({
  name: "network",
  initialState: initialNetworkState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findNetwork
    networkFetched: (state, action) => {
      state.actionsLoading = false;
      state.networkForEdit = action.payload.networkForEdit;
      state.error = null;
    },
    // createNetwork
    networkCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateNetwork
    networkUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    
  }
});
