import axios from "axios";
import { config } from "../../../../../config/config";

export const CUSTOMERS_URL = "api/rewardItems";

axios.defaults.headers.post["Content-Type"] = "application/json";

// CREATE =>  POST: add a new rewardItem to the server
export function createRewardItem(rewardItem) {
  

  return axios.post(
    `${config.API_URL}/api/reward/rewardItem/${rewardItem.rewardId}`,
    rewardItem
  );
}

// READ
export function getAllRewardItems() {
  return axios.get(CUSTOMERS_URL);
}

export function getRewardItemById(rewardItemId) {
  return axios.get(`${config.API_URL}/api/reward/rewardItem/${rewardItemId}`);
}

export function getRewardItemDetailsById(rewardItemId) {
  return axios.get(`${config.API_URL}/api/users/details/${rewardItemId}`);
}

// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findRewardItems(queryParams, rewardId) {
  return axios.post(
    `${config.API_URL}/api/reward/rewardsItems/${rewardId}?group=false`,
    JSON.stringify(queryParams)
  );
}

// UPDATE => PUT: update the rewardItem on the server
export function updateRewardItem(rewardItem) {
  
  return axios.put(`${config.API_URL}/api/reward/rewardItem/${rewardItem._id}`, rewardItem);
}

// UPDATE Status
export function updateStatusForRewardItems(ids, status) {
  return axios.put(`${config.API_URL}/api/rewardItem/state/ids`, {
    ids,
    status,
  });
}

// DELETE => delete the rewardItem from the server
export function deleteRewardItem(rewardItemId) {
  return axios.delete(`${config.API_URL}/api/reward/rewardItem/${rewardItemId}`);
}

// DELETE RewardItems by ids
export function deleteRewardItems(ids) {
  return axios.post(`${config.API_URL}/api/reward/deleteItems`, { ids });
}
