import {createSlice} from "@reduxjs/toolkit";

const initialNativeAdsState = {
  listLoading: false,
  actionsLoading: false,
  totalCount: 0,
  entities: null,
  nativeAdsForEdit: undefined,
  lastError: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const nativeAdsSlice = createSlice({
  name: "nativeAds",
  initialState: initialNativeAdsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    // findNativeAds
    nativeAdsFetched: (state, action) => {
      state.actionsLoading = false;
      state.nativeAdsForEdit = action.payload.nativeAdsForEdit;
      state.error = null;
    },
    // createNativeAds
    nativeAdsCreated: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
    },
    // updateNativeAds
    nativeAdsUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    
  }
});
