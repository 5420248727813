import React from "react";
import { Route } from "react-router-dom";
import { CustomersLoadingDialog } from "./customers-loading-dialog/CustomersLoadingDialog";
import { CustomerEditDialog } from "./customer-edit-dialog/CustomerEditDialog";
import { CustomersFetchDialog } from "./customers-fetch-dialog/CustomersFetchDialog";
import { CustomersUpdateStateDialog } from "./customers-update-status-dialog/CustomersUpdateStateDialog";
import { CustomerFetchDetails } from "./customer-fetch-details/CustomerFetchDetails";
import { CustomersUIProvider } from "./CustomersUIContext";
import { CustomersCard } from "./CustomersCard";

export function CustomersPage({ history }) {
  const customersUIEvents = {
    openEditCustomerDialog: (_id) => {
      history.push(`/dash/customers/${_id}/edit`);
    },
    openFetchCustomersDialog: () => {
      history.push(`/dash/customers/fetch`);
    },
    openUpdateCustomersStatusDialog: () => {
      history.push("/dash/customers/updateStatus");
    },
    openFetchCustomersDetailsDialog: (_id) => {
      history.push(`/dash/customers/${_id}/details`);
    }
  }

  return (
    <CustomersUIProvider customersUIEvents={customersUIEvents}>
      <CustomersLoadingDialog />
      <Route path="/dash/customers/:_id/edit">
        {({ history, match }) => (
          <CustomerEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {

              history.push("/dash/customers");
            }}
          />
        )}
      </Route>
      <Route path="/dash/customers/:_id/details">
        {({ history, match }) => (
          <CustomerFetchDetails
            show={match != null}
            id={match && match.params._id}
            onHide={() => {

              history.push("/dash/customers");
            }}
          />
        )}
      </Route>
      <Route path="/dash/customers/fetch">
        {({ history, match }) => (
          <CustomersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/customers");
            }}
          />
        )}
      </Route>
      <Route path="/dash/customers/updateStatus">
        {({ history, match }) => (
          <CustomersUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/customers");
            }}
          />
        )}
      </Route>
      <CustomersCard />
    </CustomersUIProvider>
  );
}
