const prod = {
    API_URL: "https://dashboard-dot-appnabil2021.uc.r.appspot.com",
    API_Postback: "https://api.appnabil.com",

  };
  const dev = {
      API_URL: "http://localhost:5001",
      API_Postback: "http://localhost:5000",
  };

  console.log(process.env.NODE_ENV);
  
export const config = process.env.NODE_ENV === "development" ? dev : prod;