// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useEffect, useState } from "react";
import { Formik, Field } from "formik";
import { Card, Button, Form, Row, Col } from "react-bootstrap";

import * as Yup from "yup";
import {
  Input,
  ModalProgressBar,
} from "../../../../../../../_metronic/_partials/controls";
import { Switch } from "@material-ui/core";

// Validation schema
const NetworkEditSchema = Yup.object().shape({
  owClick: Yup.number()
    .min(0, "Minimum 0")
    .max(10, "maximum 10")
    .required("Numbers Of Clicks in Offerwall is required"),
  navClick: Yup.number()
    .min(0, "Minimum 0")
    .max(10, "maximum 10")
    .required("Numbers Of Clicks in Nav is required"),
});

export function ApplovinForm({
  saveNetwork,
  network,
  actionsLoading,
  history,
}) {
  const [check, setChecked] = useState(false);
  const [check1, setChecked1] = useState(false);
  const [check2, setChecked2] = useState(false);
  const [check3, setChecked3] = useState(false);

  const backToNetworkList = () => {
    history.push(`/dash/network`);
  };

  useEffect(() => {
    network.openEnable === 0 ? setChecked(false) : setChecked(true);
    network.owEnable === 0 ? setChecked1(false) : setChecked1(true);
    network.navClickEnable === 0 ? setChecked2(false) : setChecked2(true);
    network.bannerEnable === 0 ? setChecked3(false) : setChecked3(true);
  }, [network]);

  return (
    <>
      {actionsLoading && <ModalProgressBar />}
      <Card.Body>
        <Formik
          enableReinitialize={true}
          initialValues={network}
          validationSchema={NetworkEditSchema}
          onSubmit={(values) => {
            saveNetwork(values);
          }}
        >
          {({ handleSubmit, setFieldValue }) => (
            <>
              <Form>
                <Row>
                  <Col>
                    <Field
                      name="owClick"
                      component={Input}
                      placeholder="Numbers Of Clicks in Offerwall"
                      label="Numbers Of Clicks in Offerwall"
                      type="number"
                    />
                  </Col>
                  <Col>
                  <Field
                      name="navClick"
                      component={Input}
                      placeholder="Numbers Of Clicks in Nav"
                      label="Numbers Of Clicks in Nav"
                      type="number"
                    />
                  </Col>
                </Row>
                &nbsp;
                <Row>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable In App Open:
                      </label>
                      <Switch
                        checked={check}
                        onChange={(e) => {
                          setChecked(e.target.checked);
                          let x;
                          e.target.checked ? (x = 1) : (x = 0);
                          setFieldValue("openEnable", x);
                        }}
                        value="check"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable In Offerwall:
                      </label>
                      <Switch
                        checked={check1}
                        onChange={(e) => {
                          setChecked1(e.target.checked);
                          let x;
                          e.target.checked ? (x = 1) : (x = 0);
                          setFieldValue("owEnable", x);
                        }}
                        value="check1"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Col>
                </Row>
                &nbsp;
                <Row>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable in Nav Click:
                      </label>
                      <Switch
                        checked={check2}
                        onChange={(e) => {
                          setChecked2(e.target.checked);
                          let x;
                          e.target.checked ? (x = 1) : (x = 0);
                          setFieldValue("navClickEnable", x);
                        }}
                        value="check2"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Col>
                  <Col>
                    <div style={{ display: "flex" }}>
                      <label style={{ padding: "10px" }} htmlFor="switch">
                      Enable Banner:
                      </label>
                      <Switch
                        checked={check3}
                        onChange={(e) => {
                          setChecked3(e.target.checked);
                          let x;
                          e.target.checked ? (x = 1) : (x = 0);
                          setFieldValue("bannerEnable", x);
                        }}
                        value="check3"
                        inputProps={{ "aria-label": "primary checkbox" }}
                      />
                    </div>
                  </Col>
                </Row>
                &nbsp;
              </Form>
              <>
                <Button
                  variant="primary"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Save
                </Button>
                <Button onClick={backToNetworkList} variant="secondry">
                  Cancel
                </Button>
              </>
            </>
          )}
        </Formik>
      </Card.Body>
      
    </>
  );
}
