import React from "react";
import { Route } from "react-router-dom";
import { OffersLoadingDialog } from "./offers-loading-dialog/OffersLoadingDialog";
import { OffersFetchDialog } from "./offers-fetch-dialog/OffersFetchDialog";
import { OfferFetchDetails } from "./offer-fetch-details/OfferFetchDetails";
import { OffersUIProvider } from "./OffersUIContext";
import { OffersCard } from "./OffersCard";

export function OffersPage({ history }) {
  const offersUIEvents = {
    openFetchOffersDialog: () => {
      history.push(`/dash/offers/fetch`);
    },
    openFetchOffersDetailsDialog: (_id) => {
      history.push(`/dash/offers/${_id}/details`);
    },
    
  }

  return (
    <OffersUIProvider offersUIEvents={offersUIEvents}>
      <OffersLoadingDialog />
      
      <Route path="/dash/offers/:_id/details">
        {({ history, match }) => (
          <OfferFetchDetails
            show={match != null}
            id={match && match.params._id}
            onHide={() => {

              history.push("/dash/offers");
            }}
          />
        )}
      </Route>
      <Route path="/dash/offers/fetch">
        {({ history, match }) => (
          <OffersFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/offers");
            }}
          />
        )}
      </Route>
      <OffersCard />
    </OffersUIProvider>
  );
}
