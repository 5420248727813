import React, { useEffect, useState } from "react";
import { Input } from "../../../../../../_metronic/_partials/controls";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import * as actions from "../../../_redux/socialconfig/socialconfigActions";
import { TextArea } from "../../../../../../_metronic/_partials/controls/forms/TextArea";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Card } from "react-bootstrap";

const Socialconfig = {
  _id: undefined,
  appId: "",
  appSecret: "",
  appPage: "",
  shareText: "",
};
const SocialconfigEditSchema = Yup.object().shape({
  appId: Yup.string()
    .min(1, "Minimum 1 symbols")
    .required("Google Client ID is required"),
  appSecret: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 200 symbols")
    .required("Google Client Secret is required"),
});
function GoogleEditForm({name}) {
  const { user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const { actionsLoading, googleconfigForEdit } = useSelector(
    (state) => ({
      actionsLoading: state.socialconfig.actionsLoading,
      googleconfigForEdit: state.socialconfig.googleconfigForEdit,
    }),
    shallowEqual
  );

  useEffect(() => {
    // server call for getting Network by id
    dispatch(actions.fetchGoogleconfig(user._id));
  }, [user, dispatch]);
  const saveSocialconfig = (socialconfig) => {
    if (!socialconfig._id) {
      socialconfig.adminId = user._id;
      socialconfig.name=name;
      // server request for creating network
      dispatch(actions.createSocialconfig(socialconfig)).then();
    } else {
      // server request for updating network
      dispatch(actions.updateSocialconfig(socialconfig)).then();
    }
  };

  return (
    <Formik
      enableReinitialize={true}
      initialValues={googleconfigForEdit || Socialconfig}
      validationSchema={SocialconfigEditSchema}
      onSubmit={(values) => {
        
        saveSocialconfig(values);
      }}
    >
      {({ handleSubmit }) => (
        <>
          <Form className="form form-label-right ">
          <Card.Header>
            <div className="form-group row">
              {/* Product Name */}
              <div className="col-lg-10">
                <Field
                  name="appId"
                  component={Input}
                  placeholder="Google Client ID"
                  label="Google Client ID"
                />
              </div>
              {/* Moto Name */}
              <div className="col-lg-10">
                <Field
                  name="appSecret"
                  component={Input}
                  placeholder="Google Client Secret"
                  label="Google Client Secret"
                />
              </div>
            </div>
            </Card.Header>
          </Form>
          <div className="form-group row">
            {/* Endpoint Name */}
            <div className="col-lg-6">
              <button
                type="button"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
              {actionsLoading && (
                <div
                  style={{ float: "right" }}
                  className="overlay-layer bg-transparent"
                >
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
}

export default GoogleEditForm;
