import axios from "axios";
import { config } from "../../../../../config/config";

export const CUSTOMERS_URL = "api/giveaway";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result
export function findGiveaway(id) {
  return axios.get(`${config.API_URL}/api/giveaway/${id}`);
}

export function updateGiveaway(giveaway) {
  return axios.put(`${config.API_URL}/api/giveaway/${giveaway._id}`, giveaway );
}

export function createGiveaway(giveaway) {
  return axios.post(`${config.API_URL}/api/giveaway/`, giveaway);
}

