import * as requestFromServer from "./networkCrud";
import {networkSlice, callTypes} from "./networkSlice";

const {actions} = networkSlice;

export const fetchNetwork = (name,admin) => dispatch => {
  if (!name) {
    return dispatch(actions.networkFetched({ networkForEdit: undefined }));
  }

  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .findNetwork(name,admin)
    .then(response => {
      const network = response.data;
      dispatch(actions.networkFetched({ networkForEdit: network }));
    })
    .catch(error => {
      error.clientMessage = "Can't find network";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      dispatch(actions.networkFetched({ networkForEdit: undefined }));
    });
};

export const createNetwork = networkForCreation => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .createNetwork(networkForCreation)
    .then(response => {
      const { network } = response.data;
      dispatch(actions.networkCreated(network ));
    })
    .catch(error => {
      error.clientMessage = "Can't create network";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};

export const updateNetwork = network => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return requestFromServer
    .updateNetwork(network)
    .then(() => {
      dispatch(actions.networkUpdated(network ));
    })
    .catch(error => {
      error.clientMessage = "Can't update network";
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
