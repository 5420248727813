import React, { useMemo } from "react";
import { RewardItemsFilter } from "./RewardItemsFilter";
import { RewardItemsTable } from "./RewardItemsTable";
import { RewardItemsLoadingDialog } from "./RewardItemsLoadingDialog";
import { RewardItemsDeleteDialog } from "./RewardItemsDeleteDialog";
import { RewardItemDeleteDialog } from "./RewardItemDeleteDialog";
import { RewardItemsFetchDialog } from "./RewardItemsFetchDialog";
import { RewardItemsGrouping } from "./RewardItemsGrouping";
import { RewardItemEditDialog } from "./rewardItem-edit-dialog/RewardItemEditDialog";
import { useRewardItemsUIContext } from "./RewardItemsUIContext";

export function RewardItems() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();

  const rewardItemsUIProps = useMemo(() => {
    return { ids: rewardItemsUIContext.ids };
  }, [rewardItemsUIContext]);

  return (
    <>
      <RewardItemsLoadingDialog />
      <RewardItemEditDialog />
      <RewardItemDeleteDialog />
      <RewardItemsDeleteDialog />
      <RewardItemsFetchDialog />
      <div className="form margin-b-30">
        <RewardItemsFilter />
        {rewardItemsUIProps.ids.length > 0 && <RewardItemsGrouping />}
      </div>
      <RewardItemsTable />
    </>
  );
}
