// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,

} from "../../../../../../../../_metronic/_partials/controls";


// Validation schema


export function RewardItemEditForm({ saveRewardItem, rewardItem, actionsLoading, onHide }) {

  const RewardItemEditSchema = Yup.object().shape({
    cost: Yup.string()
      .min(1, "Minimum 1 symbols")
      .max(50, "Maximum 50 symbols")
      .required("RewardItem Name is required"),
    points: Yup.number().required("Points is required"),
  });

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={rewardItem}
        validationSchema={RewardItemEditSchema}
        onSubmit={(values) => {
          
          saveRewardItem(values);
        }}
      >
        {({ handleSubmit }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="cost"
                      component={Input}
                      placeholder="Reward Cost"
                      label="RewardItem Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="points"
                      component={Input}
                      type="number"
                      placeholder="Reward Points"
                      label="Reward Points"
                    />
                  </div>
                </div>
               
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
