/* eslint-disable no-restricted-imports */
import React, { useEffect, useMemo } from "react";
import { Modal } from "react-bootstrap";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { ModalProgressBar } from "../../../../../../../_metronic/_partials/controls";
import * as actions from "../../../../_redux/rewardItems/rewardItemsActions";
import { useRewardItemsUIContext } from "./RewardItemsUIContext";

export function RewardItemsDeleteDialog() {
  // RewardItems UI Context
  const rewardItemsUIContext = useRewardItemsUIContext();
  const rewardItemsUIProps = useMemo(() => {
    return {
      ids: rewardItemsUIContext.ids,
      setIds: rewardItemsUIContext.setIds,
      rewardId: rewardItemsUIContext.rewardId,
      queryParams: rewardItemsUIContext.queryParams,
      showDeleteRewardItemsDialog: rewardItemsUIContext.showDeleteRewardItemsDialog,
      closeDeleteRewardItemsDialog: rewardItemsUIContext.closeDeleteRewardItemsDialog,
    };
  }, [rewardItemsUIContext]);

  const dispatch = useDispatch();
  const { isLoading } = useSelector(
    (state) => ({ isLoading: state.rewardItems.actionsLoading }),
    shallowEqual
  );

  useEffect(() => {}, [isLoading, dispatch]);
  useEffect(() => {
    if (!rewardItemsUIProps.ids || rewardItemsUIProps.ids.length === 0) {
      rewardItemsUIProps.closeDeleteRewardItemsDialog();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rewardItemsUIProps.ids]);

  const deleteRewardItems = () => {
    dispatch(actions.deleteRewardItems(rewardItemsUIProps.ids)).then(() => {
      dispatch(
        actions.fetchRewardItems(
          rewardItemsUIProps.queryParams,
          rewardItemsUIProps.rewardId
        )
      ).then(() => {
        rewardItemsUIProps.setIds([]);
        rewardItemsUIProps.closeDeleteRewardItemsDialog();
      });
    });
  };

  return (
    <Modal
      show={rewardItemsUIProps.showDeleteRewardItemsDialog}
      onHide={rewardItemsUIProps.closeDeleteRewardItemsDialog}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      {isLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">
          RewardItems Delete
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!isLoading && (
          <span>Are you sure to permanently delete selected rewardItems?</span>
        )}
        {isLoading && <span>RewardItems are deleting...</span>}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={rewardItemsUIProps.closeDeleteRewardItemsDialog}
            className="btn btn-light btn-elevate"
          >
            Cancel
          </button>
          <> </>
          <button
            type="button"
            onClick={deleteRewardItems}
            className="btn btn-primary btn-elevate"
          >
            Delete
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}
