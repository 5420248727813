/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { shallowEqual, useSelector } from "react-redux";
import { Modal } from "react-bootstrap";
import { ModalProgressBar } from "../../../../../../../../_metronic/_partials/controls";

export function RewardItemEditDialogHeader({ id }) {
  const [title, setTitle] = useState("");
  // RewardItems Redux state
  const { rewardItemForEdit, actionsLoading } = useSelector(
    (state) => ({
      rewardItemForEdit: state.rewardItems.rewardItemForEdit,
      actionsLoading: state.rewardItems.actionsLoading,
    }),
    shallowEqual
  );

  useEffect(() => {
    let _title = id ? "" : "New Reward Item";
    if (rewardItemForEdit && id) {
      _title = "Edit Reward Item "+rewardItemForEdit._id;
    }

    setTitle(_title);
    // eslint-disable-next-line
  }, [rewardItemForEdit, actionsLoading]);

  return (
    <>
      {actionsLoading && <ModalProgressBar variant="query" />}
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg">{title}</Modal.Title>
      </Modal.Header>
    </>
  );
}
