import axios from "axios";
import { config } from "../../../../../config/config";

export const CUSTOMERS_URL = "api/notification";

axios.defaults.headers.post["Content-Type"] = "application/json";


// Method from server should return QueryResultsModel(items: any[], totalsCount: number)
// items => filtered/sorted result

export function createNotification(notification) {
  return axios.post(`${config.API_URL}/api/notification/`, notification);
}

