import React, { useMemo } from "react";
import { useRewardsUIContext } from "./RewardsUIContext";

export function RewardsGrouping() {
  // Rewards UI Context
  const rewardsUIContext = useRewardsUIContext();
  const rewardsUIProps = useMemo(() => {
    return {
      ids: rewardsUIContext.ids,
      openDeleteRewardsDialog: rewardsUIContext.openDeleteRewardsDialog,
      openFetchRewardsDialog: rewardsUIContext.openFetchRewardsDialog,
    };
  }, [rewardsUIContext]);

  return (
    <div className="form">
      <div className="row align-items-center form-group-actions margin-top-20">
        <div className="col-xl-12">
          <div className="form-group form-group-inline">
            <div className="form-label form-label-no-wrap">
              <label className="font-bold font-danger mt-5">
                <span>Selected records count: {rewardsUIProps.ids.length}</span>
              </label>
            </div>
            <div className="form-group-inline">
              <button
                type="button"
                className="btn btn-danger font-weight-bolder font-size-sm"
                onClick={rewardsUIProps.openDeleteRewardsDialog}
              >
                <i className="fa fa-trash"></i> Delete All
              </button>
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
