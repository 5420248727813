// Form is based on Formik
// Data validation is based on Yup
// Please, be familiar with article first:
// https://hackernoon.com/react-form-validation-with-formik-and-yup-8b76bda62e10
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Input,
} from "../../../../../../../../_metronic/_partials/controls";
import Upload from "../../../../setting/components/Upload";

// Validation schema


export function RewardEditForm({ saveReward, reward, actionsLoading, onHide }) {

  const RewardEditSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, "Minimum 2 symbols")
      .max(50, "Maximum 50 symbols")
      .required("Reward Name is required"),
    order: Yup.number().required("Priority is required"),
    file: reward._id ? Yup.mixed().optional(): Yup.mixed().required(),
  });

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Choose Image");

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={reward}
        validationSchema={RewardEditSchema}
        onSubmit={(values) => {
          
          saveReward(values);
        }}
      >
        {({ handleSubmit,setFieldValue }) => (
          <>
            <Modal.Body className="overlay overlay-block">
              {actionsLoading && (
                <div className="overlay-layer bg-transparent">
                  <div className="spinner spinner-lg spinner-success" />
                </div>
              )}
              <Form className="form form-label-right">
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="name"
                      component={Input}
                      placeholder="Reward Name"
                      label="Reward Name"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                    <Field
                      name="order"
                      component={Input}
                      type="number"
                      placeholder="Priority"
                      label="Priority"
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <div className="col-lg-12">
                  <Upload
                  label="Select App Logo"
                  fileName={fileName}
                  file={file}
                  name="file"
                  width="150"
                  height="150"
                  onChange={(event) => {
                    setFieldValue("file", event.currentTarget.files[0]);
                    setFileName(event.target.files[0].name);
                    setFile(URL.createObjectURL(event.target.files[0]));
                  }}
                />
                    
                  </div>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="button"
                onClick={onHide}
                className="btn btn-light btn-elevate"
              >
                Cancel
              </button>
              <> </>
              <button
                type="submit"
                onClick={() => handleSubmit()}
                className="btn btn-primary btn-elevate"
              >
                Save
              </button>
            </Modal.Footer>
          </>
        )}
      </Formik>
    </>
  );
}
