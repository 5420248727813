import React, { useMemo } from "react";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../../_metronic/_partials/controls";
import { OffersFilter } from "./offers-filter/OffersFilter";
import { OffersTable } from "./offers-table/OffersTable";
import { OffersGrouping } from "./offers-grouping/OffersGrouping";
import { useOffersUIContext } from "./OffersUIContext";

export function OffersCard() {
  const offersUIContext = useOffersUIContext();
  const offersUIProps = useMemo(() => {
    return {
      ids: offersUIContext.ids,
      newOfferButtonClick: offersUIContext.newOfferButtonClick,
    };
  }, [offersUIContext]);

  return (
    <Card>
      <CardHeader title="Completed Offers">
      </CardHeader>
      <CardBody>
        <OffersFilter />
        {offersUIProps.ids.length > 0 && <OffersGrouping />}
        <OffersTable />
      </CardBody>
    </Card>
  );
}
