import React from "react";
import { Route } from "react-router-dom";
import { CountriesLoadingDialog } from "./countries-loading-dialog/CountriesLoadingDialog";
import { CountryEditDialog } from "./country-edit-dialog/CountryEditDialog";
import { CountryDeleteDialog } from "./country-delete-dialog/CountryDeleteDialog";
import { CountriesDeleteDialog } from "./countries-delete-dialog/CountriesDeleteDialog";
import { CountriesFetchDialog } from "./countries-fetch-dialog/CountriesFetchDialog";
import { CountriesUpdateStateDialog } from "./countries-update-status-dialog/CountriesUpdateStateDialog";
import { CountryFetchDetails } from "./country-fetch-details/CountryFetchDetails";
import { CountriesUIProvider } from "./CountriesUIContext";
import { CountriesCard } from "./CountriesCard";

export function CountriesPage({ history }) {
  const countriesUIEvents = {
    newCountryButtonClick: () => {
      history.push("/dash/rewards/countries/new");
    },
    openEditCountryDialog: (_id) => {
      history.push(`/dash/rewards/countries/${_id}/edit`);
    },
    openFetchCountriesDialog: () => {
      history.push(`/dash/rewards/countries/fetch`);
    },
    openUpdateCountriesStatusDialog: () => {
      history.push("/dash/rewards/countries/updateStatus");
    },
    openFetchCountriesDetailsDialog: (_id) => {
      history.push(`/dash/rewards/countries/${_id}/details`);
    },
    openDeleteCountryDialog:  (_id) => {
      history.push(`/dash/rewards/countries/${_id}/cancel`);
    },
    openDeleteCountriesDialog: () => {
      history.push(`/dash/rewards/countries/deleteCountries`);
    },
    openAddRewardsToCountryDialog: (_id) => {
      history.push(`/dash/countries/${_id}/add`);
    }
  }

  return (
    <CountriesUIProvider countriesUIEvents={countriesUIEvents}>
      <CountriesLoadingDialog />
      <Route path="/dash/rewards/countries/new">
        {({ history, match }) => (
          <CountryEditDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/rewards/countries");
            }}
          />
        )}
        </Route>
        <Route path="/dash/rewards/countries/:_id/edit">
        {({ history, match }) => (
          <CountryEditDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/rewards/countries");
            }}
          />
        )}
        </Route>
      <Route path="/dash/rewards/countries/:_id/cancel">
        {({ history, match }) => (
          <CountryDeleteDialog
            show={match != null}
            id={match && match.params._id}
            onHide={() => {
              history.push("/dash/rewards/countries");
            }}
          />
        )}
      </Route>
      <Route path="/dash/rewards/countries/:_id/details">
        {({ history, match }) => (
          <CountryFetchDetails
            show={match != null}
            id={match && match.params._id}
            onHide={() => {

              history.push("/dash/rewards/countries");
            }}
          />
        )}
      </Route>
      <Route path="/dash/rewards/countries/fetch">
        {({ history, match }) => (
          <CountriesFetchDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/rewards/countries");
            }}
          />
        )}
      </Route>
      <Route path="/dash/rewards/countries/updateStatus">
        {({ history, match }) => (
          <CountriesUpdateStateDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/rewards/countries");
            }}
          />
        )}
      </Route>
      <Route path="/dash/rewards/countries/deleteCountries">
        {({ history, match }) => (
          <CountriesDeleteDialog
            show={match != null}
            onHide={() => {
              history.push("/dash/rewards/countries");
            }}
          />
        )}
      </Route>
      <CountriesCard />
    </CountriesUIProvider>
  );
}
